import React, { useState } from "react";
import Styles from "./Styles.module.css";
import { Link } from "react-router-dom";
import { Facebook, Instagram, LinkedIn, WhatsApp } from "@mui/icons-material";

const Footer = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 100) {
      setVisible(true);
    } else if (scrolled <= 100) {
      setVisible(false);
    }
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <div
      className={Styles.slider8}
      style={{ backgroundColor: "var(--primaryColor)!important" }}
    >
      <div className={Styles.mainContainer}>
        <div className={Styles.footer}>
          <div className={Styles.socials}>
            {" "}
            <Link to="#/">Terms & Conditions </Link>{" "}
            <Link to="#/"> Privacy Policy </Link>
            <Link to="#/"> Accessibility Statement </Link>
            <Link to="#/"> Site Maps </Link>
          </div>

          <div className={Styles.socials}>
            <span>
              <Facebook />
            </span>
            <span>
              <WhatsApp />
            </span>
            <span>
              <Instagram />
            </span>
            <span>
              <LinkedIn />
            </span>
          </div>
        </div>
        <div className={Styles.tcc}>
          <p>
            Copyright : &copy; {new Date().getFullYear()} REGIONAL MARITIME
            SECURITY ARCHITECTURE (RMSA). All Rights Reserved
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
