import React from "react";
import Header from "./components/header/headerfr";
import HomeSlider from "./components/homeslider/homesliderfr";
import MainCenters from "./components/centers/maincentersfr";
import Sponsors from "./components/sponsors/sponsors";
import Footer from "./components/footer/footerfr";

const HomeFr = () => {
  return (
    <div>
      <Header />
      <HomeSlider />
      <MainCenters />
      <Sponsors />
      <Footer/>
    </div>
  );
};

export default HomeFr;
