import PartnerCarouselFlags from "./partnercarouselflags";
import React from "react";

function RCOCPartnerFlags() {
  return (
    <div style={{ margin: "3rem" }}>
      <div>
        <h3
          style={{
            fontSize: "2rem",
            textAlign: "center",
            color: "var(--primaryColor)",
            fontWeight: "bolder",
          }}
        >
          Our Partners
        </h3>
        <div>
          <PartnerCarouselFlags />
        </div>
      </div>
    </div>
  );
}

export default RCOCPartnerFlags;
