import React from "react";
import Styles from "./Styles.module.css";
import { Zoom } from "react-awesome-reveal";
import SkeletonDisplay from "../../hooks/skeletonDisplay";

const ThematicAreas = () => {
  return (
    <div className={Styles.slider2}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img
                src="/rcoc/thematicareas.png"
                alt="Regional Coordination Operations Centre (RCOC)"
              />
            </SkeletonDisplay>
          </div>
          <div className={Styles.productCard}>
            <Zoom>
              <div className={Styles.title}>
                  <h1>RCOC Thematic Areas</h1>
              </div>

              {Objectives.map((objective, index) => (
                <li key={index}>{objective}</li>
              ))}
            </Zoom>
            
            <div>
              <button>Learn More</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThematicAreas;

const Objectives = [
  "Violence at sea",
  "IUU Fishing",
  "Human trafficking at sea",
  "Traffick and contraband at sea",
  " Weapons proliferation and security initiative",
  "Maritime incident",
  "Natural event",
  "Maritime Cybercrime",
  "Critical infrastructure",
  "Yachting and maritime tourism",
  "Marine environment.",
];
