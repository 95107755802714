import React from "react";
import Styles from "./Styles.module.css";
import { Zoom } from "react-awesome-reveal";
import SkeletonDisplay from "../../hooks/skeletonDisplay";

const Mission = () => {
  return (
    <div className={Styles.slider2}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <h1>Our Mission</h1>

            <p>
            The Regional Maritime Information Fusion Center (RMIFC) is dedicated to enhancing maritime security and safety through the comprehensive collection, merging, analysis, and sharing of maritime information. By serving as an indispensable tool for understanding the maritime situation, RMIFC supports operations at sea, assists in critical decision-making and strategic projection, and fosters collaboration. Our platform facilitates exchanges between the public sector, private sector, and civil society, thereby strengthening the maritime community. Additionally, RMIFC provides robust hosting and support to ensure the continuous advancement of maritime intelligence and operations.
            </p>
          

            <div className={Styles.vision}>
              <h1>Our Vision</h1>
              <p>
                To make the CRCO a centre of excellence with autonomous
                administrative, legal and financial management.
              </p>
            </div>
            <div>
              <button>Learn More</button>
            </div>
          </div>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img
                src="/rcoc/ourmission.png"
                alt="Regional Coordination Operations Centre (RCOC)"
              />
            </SkeletonDisplay>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mission;

const Objectives = [
  "Combating maritime piracy, Combating organized crime at sea and the conduct of illegal activities at sea, Assistance in the exercise of the prerogatives of States with regard to ships flying their flag or flying no flag",
  "Participation in the exercise and rescue of human lives at sea, within the framework of existing arrangements",
  "Protection of the marine environment in the event of a foreseeable threat, Participation in the restoration of the safety and security of navigation at sea or in the approaches to seaports, Protection of underwater cultural heritage",
  "Cooperation in the event of natural or environmental disasters.",
];
