import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import Styles from "./Styles.module.css";

const SponsorsCarouselFR = () => {
  const items = [
    {
      title: "OIT Comores",
      description:
        "TL'officier de liaison internationale (OIT) aux Comores soutient la sécurité maritime en reliant le Centre régional de fusion d'informations maritimes (CRFIM) avec des partenaires mondiaux. L'OIT améliore le partage d'informations et la coordination, en aidant à faire face aux menaces maritimes dans la région de l'Afrique orientale et australe et de l'océan Indien (ESA-IO), en favorisant la collaboration régionale et internationale.",
      imageUrl: "/rcoc/ilocomoros.png",
      flag: "/rcoc/madagascar.png",
      link: "#",
    },
    {
      title: "OIT Djibouti",
      description:
        "L'officier de liaison internationale (OIT) à Djibouti soutient la sécurité maritime en reliant le Centre régional de fusion d'informations maritimes (CRFIM) avec des partenaires mondiaux. L'OIT améliore le partage d'informations et la coordination, en aidant à faire face aux menaces maritimes dans la région de l'Afrique orientale et australe et de l'océan Indien (ESA-IO), en favorisant la collaboration régionale et internationale.",
      imageUrl: "/rcoc/ilodjibouti.png",
      flag: "/rcoc/djibouti.png",
      link: "#",
    },
    {
      title: "OIT France",
      description:
        "L'officier de liaison internationale (OIT) en France soutient la sécurité maritime en reliant le Centre régional de fusion d'informations maritimes (CRFIM) avec des partenaires mondiaux. L'OIT améliore le partage d'informations et la coordination, en aidant à faire face aux menaces maritimes dans la région de l'Afrique orientale et australe et de l'océan Indien (ESA-IO), en favorisant la collaboration régionale et internationale.",
      imageUrl: "/rcoc/ilofrance.png",
      flag: "/rcoc/france.png",
      link: "#",
    },
    {
      title: "OIT Madagascar",
      description:
        "L'officier de liaison internationale (OIT) à Madagascar soutient la sécurité maritime en reliant le Centre régional de fusion d'informations maritimes (CRFIM) avec des partenaires mondiaux. L'OIT améliore le partage d'informations et la coordination, en aidant à faire face aux menaces maritimes dans la région de l'Afrique orientale et australe et de l'océan Indien (ESA-IO), en favorisant la collaboration régionale et internationale.",
      imageUrl: "/rcoc/ilomadagascar.png",
      flag: "/rcoc/madagascar.png",
      link: "#",
    },
    {
      title: "OIT Seychelles",
      description:
        "L'officier de liaison internationale (OIT) à SeyChelles soutient la sécurité maritime en reliant le Centre régional de fusion d'informations maritimes (CRFIM) avec des partenaires mondiaux. L'OIT améliore le partage d'informations et la coordination, en aidant à faire face aux menaces maritimes dans la région de l'Afrique orientale et australe et de l'océan Indien (ESA-IO), en favorisant la collaboration régionale et internationale.",
      imageUrl: "/rcoc/iloseychelles.png",
      flag: "/rcoc/seychelles.png",
      link: "#",
    },
    {
      title: "OIT Kenya",
      description:
        "L'officier de liaison internationale (OIT) au Kenya soutient la sécurité maritime en reliant le Centre régional de fusion d'informations maritimes (CRFIM) avec des partenaires mondiaux. L'OIT améliore le partage d'informations et la coordination, en aidant à faire face aux menaces maritimes dans la région de l'Afrique orientale et australe et de l'océan Indien (ESA-IO), en favorisant la collaboration régionale et internationale.",
      imageUrl: "/rcoc/iloKenya.png",
      flag: "/rcoc/madagascar.png",
      link: "#",
    },
  ];

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Carousel responsive={responsive}>
      {items.map((item, index) => (
        <div
          key={index}
          style={{
            padding: "1rem",
            border: "1px solid #ddd",
            borderRadius: "8px",
            backgroundColor: "#f9f9f9",
            textAlign: "center",
            margin: "1rem",
          }}
        >
          <Link to={item.link}>
            <img
              src={item.imageUrl}
              fill
              alt={item.title}
              className={Styles.image}
            />
            <div className={Styles.subContainer}>
              <h3>{item.title}</h3>
              <img
                src={item.flag}
                fill
                alt={item.title}
                className={Styles.imageFlag}
              />
            </div>
            <p>{item.description.substring(0, 120)}</p>
          </Link>
        </div>
      ))}
    </Carousel>
  );
};

export default SponsorsCarouselFR;
