// NavBarData.js
const navLinksMobile = [
  { title: "Home", path: "/" },
  {
    title: "À propos de nous",
    path: "/À propos de nous",
  },
  {
    title: "Notre travail",
    path: "/Notre travail",
  },
  {
    title: "Centre des médias",
    path: "/Centre des médias",
  },
  {
    title: "Ressources",
    path: "/Ressources",
  },
  {
    title: "Événements",
    path: "/Événements",
  },
  {
    title: "Cadre de gouvernance",
    path: "/Cadre de gouvernance",
  },

  { title: "Contactez-nous", path: "/Contactez-nous" },
];

export default navLinksMobile;
