import React from "react";
import Styles from "./Styles.module.css";

const RMIFCMissionFR = () => {
  return (
    <div className={Styles.signUp}>
      <h2>Notre Mission</h2>
      <div className={Styles.container}>
        <div className={Styles.productCard}>
          <h3>
            Collecter, fusionner, analyser et partager des informations
            maritimes relatives à :
          </h3>
          <p>
            Violence en mer, pêche INN, traite des êtres humains en mer, trafic
            et contrebande en mer, initiative sur la prolifération des armes et
            la sécurité, Incident maritime, Événement naturel, Cybercriminalité
            maritime, Critique infrastructures, Yachting et tourisme maritime,
            Environnement marin
          </p>
        </div>
      </div>
    </div>
  );
};

export default RMIFCMissionFR;
