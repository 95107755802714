import React, { useState, useEffect } from "react";
import { Facebook, Instagram, LinkedIn, WhatsApp } from "@mui/icons-material";
import Styles from "./copyright.module.css";

const CopyRight = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const toggleVisible = () => {
      const scrolled = document.documentElement.scrollTop;
      setVisible(scrolled > 100);
    };

    window.addEventListener("scroll", toggleVisible);
    return () => window.removeEventListener("scroll", toggleVisible);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className={Styles.slider8} style={{ backgroundColor: "var(--primaryColor)!important" }}>
      <div className={Styles.mainContainer}>
        <div className={Styles.footer}>
          <div className={Styles.socials}>
            <a href="#/" onClick={scrollToTop}>
              <p>Terms & Conditions</p>
            </a>
            <a href="#/" onClick={scrollToTop}>
              <p>Privacy Policy</p>
            </a>
            <a href="#/" onClick={scrollToTop}>
              <p>Accessibility Statement</p>
            </a>
            <a href="#/" onClick={scrollToTop}>
              <p>Site Maps</p>
            </a>
          </div>

          <div className={Styles.socials}>
            <span>
              <Facebook />
            </span>
            <span>
              <WhatsApp />
            </span>
            <span>
              <Instagram />
            </span>
            <span>
              <LinkedIn />
            </span>
          </div>
        </div>
        <div className={Styles.tcc}>
          <p>
            Copyright : &copy; {new Date().getFullYear()} Regional Coordination
            Operations Centre (RCOC). All Rights Reserved
          </p>
        </div>
      </div>
    </div>
  );
};

export default CopyRight;
