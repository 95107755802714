import React from "react";
import { Outlet } from "react-router-dom";
import LanguageBar from "../LanguageBar/languagebar";
import FooterComp from "../../rcoc/footer/footercomp";
import HomeSlider from "../../rcoc/slider/homeslider";
import NavBar from "../../rcoc/navbar/navbar";

function RCOCLayout2() {
  return (
    <div>
      <LanguageBar />
      <NavBar />
      <HomeSlider />
      <div>
        <Outlet />
      </div>
      <FooterComp />
    </div>
  );
}

export default RCOCLayout2;
