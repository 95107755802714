import React from "react";
import SkeletonDisplay from "../../hooks/skeletonDisplay";
import Styles from "./Styles.module.css";

const HomeSliderFR = () => {
  return (
    <div>
      <SkeletonDisplay>
        <img
          src="/rmifc/homeslider.jpg"
          fill
          alt="Regional Maritime Information Fusion Centre (RMFIC)"
          className={Styles.image}
        />
      </SkeletonDisplay>
      <h1 className={Styles.containerTitle}>
        Axé sur la fourniture de véritables mises à jour maritimes dans l'océan
        Indien africain
      </h1>
    </div>
  );
};

export default HomeSliderFR;
