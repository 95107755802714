import React from "react";
import Styles from "./Styles.module.css";

const OurValues = () => {
  const leftArray = Objectives.slice(0, 5);
  const rightArray = Objectives.slice(6, 10);
  return (
    <div className={Styles.slider2}>
      <div className={Styles.ourvalues}>
        <h1>Our Values</h1>
      </div>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <div className={Styles.arrayContainer}>
              <table>
                <tr>
                  <td>
                    {leftArray.map((objective, index) => (
                      <div key={index} className={Styles.arrayItem}>
                        <h1>{objective.title.substring(0, 1)}</h1>
                      </div>
                    ))}
                  </td>
                  <td>
                    {leftArray.map((objective, index) => (
                      <div>
                        {" "}
                        <h3>{objective.title}</h3>
                        <p>{objective.description}</p>
                      </div>
                    ))}
                  </td>
                </tr>
              </table>
            </div>

            <div>
              <button>Learn More</button>
            </div>
          </div>
          <div className={Styles.productCard}>
            <div className={Styles.arrayContainer}>
              <table>
                <tr>
                  <td>
                    {rightArray.map((objective, index) => (
                      <div key={index} className={Styles.arrayItem}>
                        <h1>{objective.title.substring(0, 1)}</h1>
                      </div>
                    ))}
                  </td>
                  <td>
                    {rightArray.map((objective, index) => (
                      <div>
                        {" "}
                        <h3>{objective.title}</h3>
                        <p>{objective.description}</p>
                      </div>
                    ))}
                  </td>
                </tr>
              </table>
            </div>

            <div>
              <button>Learn More</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurValues;

const Objectives = [
  {
    title: "Integrity ",
    description:
      "Upholding the highest standards of honesty and ethical behavior in all our operations and interactions.",
  },
  {
    title: "Collaboration ",
    description:
      "Fostering a cooperative environment that encourages teamwork and partnerships across public, private, and civil sectors.",
  },
  {
    title: "Excellence ",
    description:
      "Striving for the highest level of performance and continuous improvement in all our activities and services.",
  },
  {
    title: "Innovation ",
    description:
      "Embracing new technologies and methodologies to enhance our maritime information and operational capabilities.",
  },
  {
    title: "Transparency  ",
    description:
      "Ensuring openness and clear communication in our processes, decisions, and information sharing.",
  },
  {
    title: "Responsibility  ",
    description:
      "Upholding the highest standards of honesty and ethical behavior in all our operations and interactions.",
  },
  {
    title: "Adaptability ",
    description:
      "Being flexible and responsive to the dynamic nature of maritime challenges and opportunities.",
  },
  {
    title: "Professionalism ",
    description:
      "Demonstrating competence, reliability, and respect in all our interactions and endeavors.",
  },
  {
    title: "Inclusivity ",
    description:
      "Valuing diverse perspectives and promoting equal participation and representation in maritime activities.",
  },
  {
    title: "Service ",
    description:
      "Dedication to serving the maritime community and contributing to the overall well-being and advancement of the maritime sector.",
  },
];
