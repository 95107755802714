import React from "react";
import Styles from "./Styles.module.css";
import { Zoom } from "react-awesome-reveal";
import SkeletonDisplay from "../../hooks/skeletonDisplay";

const ThematicAreas = () => {
  return (
    <div className={Styles.slider2}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img
                src="/rcoc/thematicareas.png"
                alt="Regional Coordination Operations Centre (RCOC)"
              />
            </SkeletonDisplay>
          </div>
          <div className={Styles.productCard}>
            <Zoom>
              <div className={Styles.title}>
                  <h1>Domaines thématiques du CORC</h1>
              </div>

              {Objectives.map((objective, index) => (
                <li key={index}>{objective}</li>
              ))}
            </Zoom>
            
            <div>
              <button>Apprendre Encore Plus</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThematicAreas;

const Objectives = [
  "Violences en mer",
  "Pêche INN",
  "Traite d'êtres humains en mer",
  "Trafic et contrebande en mer",
  " Initiative sur la prolifération des armes et la sécurité ",
  "Incident maritime",
  "Événement naturel",
  "Cybercriminalité maritime",
  "Infrastructures critiques",
  "Plaisance et tourisme maritime",
  "Environnement marin.",
];
