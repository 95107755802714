import React from "react";
import Styles from "./stylesfr.module.css";
import { Link } from "react-router-dom";
import { AttentionSeeker } from "react-awesome-reveal";

function MainMenu() {
  return (
    <div className={Styles.Navbar}>
      <Link to="/">
        <div>
          <img
            src="/header/rmifc.png"
            alt="Centre Régional de Fusion d'Informations Maritimes (CRFIM)"
            className={Styles.leftSide}
          />
        </div>
      </Link>

      <div className={Styles.centerdiv}>
        <AttentionSeeker>
          <Link to="/">
            <h1>Centre Régional de Fusion d'Informations Maritimes (CRFIM) </h1>
          </Link>
        </AttentionSeeker>
      </div>
      <div className={Styles.rightSide}>
        <button>Portail des membres</button>
      </div>
    </div>
  );
}

export default MainMenu;
