import React from "react";
import Styles from "./Styles.module.css";
import SkeletonDisplay from "../../hooks/skeletonDisplay";
import { Link } from "react-router-dom";

const RCOCCOORDINATORFR = () => {
  return (
    <div className={Styles.slider2}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <h1>Déclaration des coordinateurs</h1>

            <p>
              En tant que directeur du Centre régional de fusion d'informations
              maritimes (CRIFC), j'ai l'honneur de réaffirmer notre engagement à
              faire progresser sûreté et sécurité maritimes en Afrique orientale
              et australe et la région de l’Océan Indien (ESA-IO). Le CRFIM
              constitue un acteur incontournable pilier du programme de sécurité
              maritime (MASE), rendu possible grâce au soutien généreux de
              l’Union européenne. Notre mission est clair : favoriser un
              environnement maritime plus sûr qui soutient la croissance
              économique, le commerce et la stabilité régionale.
            </p>
            <p>
              En collaboration avec les partenaires régionaux, le CRFIM joue un
              rôle rôle essentiel dans la lutte contre les menaces complexes qui
              menacent notre domaines maritimes, notamment la piraterie, les
              trafics, la pêche illégale, et les menaces environnementales. En
              facilitant l'échange et la fusion d'informations maritimes sur une
              vaste zone s'étendant du du Cap de Bonne-Espérance au détroit de
              Bab-El-Mandeb, nous renforçons notre capacité collective à
              détecter, dissuader et répondre aux activités en mer.
            </p>

            <div>
              <Link to="/aboutusrcocfr">
                <button>En savoir plus</button>
              </Link>
            </div>
          </div>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img
                src="/rcoc/homepage/coordinator.jpg"
                alt="Regional Coordination Operations Centre (RCOC)"
              />
            </SkeletonDisplay>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RCOCCOORDINATORFR;
