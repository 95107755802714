// Slider.js
import React, { useState, useEffect } from "react";
import SliderData from "./SliderDatafr";
import "./Slider.css";
import { Link } from "react-router-dom";

const HomeSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) =>
        prevSlide === SliderData.length - 1 ? 0 : prevSlide + 1
      );
    }, 8000); // Change slide every 3 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="maincontainer">
      {SliderData.map((slide, index) => (
        <div
          key={index}
          className={`slide ${index === currentSlide ? "active" : ""}`}
        >
          <div className="slider-container">
            <img src={`${slide.image}`} fill alt={slide.alt} />
            <div className="container">
              <Link to={slide.link}>
                <h3>{slide.text}</h3>
              </Link>
            </div>
          </div>

          <div className="indicator-bar">
            {SliderData.map((_, index) => (
              <div
                key={index}
                className={`indicator ${
                  index === currentSlide ? "active" : ""
                }`}
                onClick={() => setCurrentSlide(index)}
              ></div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default HomeSlider;
