import styled from "styled-components";

export const Heading = styled.h1`
  text-align: center;
  color: #2d5089;
`;

export const Content = styled.div`
  overflowy: scroll;
  height: 100%;
`;

export const Button = styled.div`
  position: fixed;
  width: 100%;
  left: 95%;
  bottom: 150px;
  height: 10px;
  font-size: 2rem;
  z-index: 1;
  cursor: pointer;
  color: #ADD038;
  margin-right: 2rem;
  @media (max-width: 700px) {
    left: 90%;
  }
`;
