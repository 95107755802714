const SliderData = [
  {
    id: 1,
    image: "/home/home1.png",
    alt: "RCOCRMIFC",
    text: "RMIFC Launches New AI-Powered Surveillance System to Enhance Maritime Security in the African Indian Ocean",
    link: "/rmfic1",
  },
  {
    id: 2,
    image: "/home/home2.png",
    alt: "RCOCRMIFC",
    text: "Historic Collaboration: RMIFC and Regional Partners Successfully Combat Illegal Fishing in Joint Operation",
    link: "/rmfic2",
  },
  {
    id: 3,
    image: "/home/home3.png",
    alt: "RCOCRMIFC",
    text: "Environmental Victory: RMIFC Report Highlights 20% Reduction in Marine Pollution Over Past Year",
    link: "/rmfic13",
  },
];

export default SliderData;
