import React from "react";
import SkeletonDisplay from "../../hooks/skeletonDisplay";
import Styles from "./Styles.module.css";

const HomeSlider = () => {
  return (
    <div>
      <SkeletonDisplay>
        <img
          src="/rcoc/aboutus.png"
          fill
          alt="Regional CoordinationOperations Centre (RCOC)"
          className={Styles.image}
        />
      </SkeletonDisplay>
      <h1 className={Styles.containerTitle}>
        Focused on delivering real updates maritime in the African Indian Ocean
      </h1>
    </div>
  );
};

export default HomeSlider;
