// NavBarData.js
const navLinksMobile = [
  { title: "Home", path: "/" },
  {
    title: "About Us",
    path: "/",
  },
  {
    title: "Our Work",
    path: "/",
  },
  {
    title: "Media Center",
    path: "/",
  },
  {
    title: "Resources",
    path: "/",
  },
  {
    title: "Events",
    path: "/",
  },
  {
    title: "Gorvenance Framework",
    path: "/",
  },

  { title: "Contuct us", path: "/" },
];

export default navLinksMobile;
