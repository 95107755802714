// NavBarData.js
const navLinks = [
  { title: "Home", path: "/rcoc" },

  {
    title: "About",
    path: "/#",
    subLinks: [
     "About Us",
      "Director",
      "IOC",
      "Our teams",
      "Our partners",
      "Testimonials",
    ],
  },
  {
    title: "Our Work",
    path: "/Our Work",
    subLinks: [
      "Capacity building",
      "Coordination of regional operations at sea",
      "Regional tabletop exercises (TTX)",
      "Field Training Exercises (FTX) RCOC Achievements",
    ],
  },
  {
    title: "Media Center",
    path: "/Media Center",
    subLinks: ["News", "Blog", "Gallery", "Videos"],
  },
  {
    title: "Resources",
    path: "/Resources",
    subLinks: [
      "Case studies",
      "General reports",
      "Research articles",
      "White Papers",
    ],
  },
  { title: "Events", path: "/Events" },
  {
    title: "Governance Framework",
    path: "/Governance Framework",
    subLinks: [
      "Regional agreements",
      "Council of Ministers",
      "Steering committee",
      "Signatory States",
      "Trust fund",
      "Secretariat",
    ],
  },
  { title: "Contact us", path: "/Contact us" },
];

export default navLinks;
