const ShowCases = [
  {
    center1: "1",
    title: "Regional maritime Information Fusion Centre (RMIFC) ",
    image: "/rmifc/home.png",
    description:
      "Facilitates the exchange and fusion of maritime information over an area stretching from the Cape of Good Hope (South Africa) to the Bab-El-Mandeb Strait (Djibouti), thus contributing to the fight against illegal activities and crimes committed at sea.",
    link: "/rmifc",
  },
  {
    center2: "2",
    title: "Regional Coordination Operations Centre (RCCO)",
    image: "/rcoc/home.png",
    description:
      "Facilitates the exchange and fusion of maritime information over an area stretching from the Cape of Good Hope (South Africa) to the Bab-El-Mandeb Strait (Djibouti), thus contributing to the fight against illegal activities and crimes committed at sea.",
    link: "/rcoc",
  },
];

export { ShowCases };
