import React from "react";
import Styles from "./Styles.module.css";
import SkeletonDisplay from "../../hooks/skeletonDisplay";
import { Link } from "react-router-dom";

const RMIFCCoordinator = () => {
  return (
    <div className={Styles.slider2}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <h1>Coordinator's Statement</h1>

            <p>
              <span>"</span>As the Coordinator Statement of the Regional
              Maritime Information Fusion Centre (RMIFC), I am honored to
              reaffirm our commitment to advancing maritime safety and security
              in the Eastern and Southern Africa and Indian Ocean (ESA-IO)
              region. The RMIFC stands as a vital pillar of the Maritime
              Security (MASE) programme, made possible through the generous
              support of the European Union. Our mission is clear: to foster a
              safer maritime environment that supports economic growth, trade,
              and regional stability.
            </p>
            <p>
              In collaboration with regional partners, the RMIFC plays a
              critical role in combating the complex threats that challenge our
              maritime domains, including piracy, trafficking, illegal fishing,
              and environmental threats. By facilitating the exchange and fusion
              of maritime information across a vast area stretching from the
              Cape of Good Hope to the Bab-El-Mandeb Strait, we are enhancing
              our collective ability to detect, deter, and respond to illegal
              activities at sea.
            </p>

            <div>
              <Link to="/aboutusrcoc">
                <button>Read More</button>
              </Link>
            </div>
          </div>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img
                src="/rcoc/homepage/coordinator.jpg"
                alt="Regional Coordination Operations Centre (RCOC)"
              />
            </SkeletonDisplay>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RMIFCCoordinator;
