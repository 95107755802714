import React from "react";
import AboutUsComp from "./aboutuscompfr";
import Mission from "../Our Mission/missionfr";
import ThematicAreas from "../thematicareas/thematicareasfr";
import OurValues from "../ourvalues/ourvaluesfr";
import SignUp from "../signup/signupfr";

const RMIFCAboutUsIndexFR = () => {
  return (
    <div>
      <AboutUsComp />
      <Mission />
      <ThematicAreas />
      <OurValues />
      <SignUp />
    </div>
  );
};

export default RMIFCAboutUsIndexFR;
