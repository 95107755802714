import React from "react";
import { Outlet } from "react-router-dom";
import NavBar from "../../rmifc/navbar/navbar";
import LanguageBar from "../LanguageBar/languagebar";
import FooterComp from "../../rmifc/footer/footercomp";
import HomeSlider from "../../rmifc/slider/homeslider";

function RMIFCLayout2() {
  return (
    <div>
      <LanguageBar />

      <NavBar />
      <HomeSlider />
      <div>
        <Outlet />
      </div>
      <FooterComp />
    </div>
  );
}

export default RMIFCLayout2;
