import React from "react";
import Styles from "./Styles.module.css";
import { Link } from "react-router-dom";
import NewsCarouselFR from "./newscarouselfr";
import LatestNewsDataFR from "./rcoclatestnewsdatafr";

const RMIFCEWSFR = () => {
  return (
    <div className={Styles.mainContainer}>
      <div className={Styles.container}>
        <div className={Styles.productCard}>
          <h1>RCOC Latest News</h1>
          <NewsCarouselFR />
        </div>
        <div className={Styles.productCard}>
          <div className={Styles.table}>
            <table className={Styles.table}>
              {LatestNewsDataFR.map((item, index) => (
                <tr key={index}>
                  <td className={Styles.tdimage}>
                    <img src={item.imageUrl} alt="RCOC" />
                  </td>
                  <td className={Styles.tdtext}>
                    <p>{item.description.substring(0, 200)}</p>{" "}
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RMIFCEWSFR;
