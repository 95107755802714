// NavBarData.js
const navLinksMobile = [
  { title: "Home", path: "/" },
  {
    title: "About us",
    path: "/About Us",
  },
  {
    title: "Our Work",
    path: "/Our Work",
  },
  {
    title: "Media Centre",
    path: "/Media Centre",
  },
  {
    title: "Resources",
    path: "/Resources",
  },
  {
    title: "Events",
    path: "/Events",
  },
  {
    title: "Governance FrameWork",
    path: "/Governance FrameWork",
  },

  { title: "Contact us", path: "/Contact Us" },
];

export default navLinksMobile;
