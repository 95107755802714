import React from "react";
import Styles from "./styles.module.css";
import { Link } from "react-router-dom";
import { AttentionSeeker } from "react-awesome-reveal";

function MainMenu() {
  return (
    <div className={Styles.Navbar}>
      <Link to="/">
        <div >
          <img
            src="/header/rcoc.png"
            alt="Regional Coordination Operations Centre (RCOC)"
            className={Styles.leftSide}
          />
        </div>
      </Link>
      <div className={Styles.centerdiv}>
        <AttentionSeeker>
          <Link to="/">
            <h1>Regional Coordination Operations Center (RCOC) </h1>
          </Link>
        </AttentionSeeker>
      </div>
      <div className={Styles.rightSide}>
        <button>Members Portal</button>
      </div>
    </div>
  );
}

export default MainMenu;
