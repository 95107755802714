import React from "react";
import { Routes, Route } from "react-router-dom";
import Nomatch from "./nomatch.js";
import Home from "./home";
import HomeFR from "./homefr.js";

// Layout>
import Layout from "./components/Layout/layout.js";
import RCOCLayout2 from "./components/Layout/rcoclayout2.js";
import RCOCLayout2FR from "./components/Layout/rcoclayout2fr.js";
import RMIFCLayout2 from "./components/Layout/rmifclayout2.js";
import RMIFCLayout2FR from "./components/Layout/rmifclayout2fr.js";
// RCOC
import RCOCHomePage from "./rcoc/index.js";
import RCOCHomePageFR from "./rcoc/indexfr.js";

// RMIFC
import RMIFCHomePage from "./rmifc/index.js";
import RMIFCHomePageFR from "./rmifc/indexfr.js";
import RMIFCAboutUsIndex from "./rmifc/aboutus/rmifcindex.js";
import RMIFCAboutUsIndexFR from "./rmifc/aboutus/rmifcindexfr.js";
import RCOCAboutUsIndex from "./rcoc/aboutus/rcocindex.js";
import RCOCAboutUsIndexFR from "./rcoc/aboutus/rcocindexfr.js";

function AppRouter() {
  return (
    <>
      <Routes>
        <>
          {/* Layout1 */}
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="*" element={<Nomatch />} />
            <Route path="/fr" element={<HomeFR />}></Route>
          </Route>
          {/* RCOCEN */}
          <Route path="/" element={<RCOCLayout2 />}>
            <Route path="/rcoc" element={<RCOCHomePage />}></Route>

            <Route path="/aboutusrcoc" element={<RCOCAboutUsIndex />}></Route>
          </Route>
          {/* RCOCFR */}
          <Route path="/" element={<RCOCLayout2FR />}>
            <Route path="/rcocfr" element={<RCOCHomePageFR />}></Route>
            <Route
              path="/aboutusrcocfr"
              element={<RCOCAboutUsIndexFR />}
            ></Route>
          </Route>

          {/* RMIFCEN */}
          <Route path="/" element={<RMIFCLayout2 />}>
            <Route path="/rmifc" element={<RMIFCHomePage />}></Route>
           
            <Route path="/aboutusrmifc" element={<RMIFCAboutUsIndex />}></Route>
           
          </Route>

          {/* RMIFCFR */}
          <Route path="/" element={<RMIFCLayout2FR />}>
          <Route path="/rmifcfr" element={<RMIFCHomePageFR/>}></Route>
          <Route
              path="/aboutusrmifcfr"
              element={<RMIFCAboutUsIndexFR />}
            ></Route>
          </Route>
        </>
      </Routes>
    </>
  );
}

export default AppRouter;
