const LatestNewsDataFR = [
  {
    title:
      "Renforcement de la sécurité maritime : les Seychelles accueillent un nouvel agent de liaison internationale",
    description:
      "Les Seychelles renforcent la sécurité maritime avec la nomination d'un officier de liaison internationale (OIT) au Centre régional de fusion d'informations maritimes (CRFIM), renforçant ainsi les partenariats mondiaux et favorisant la sécurité régionale.",
    imageUrl: "/rcoc/news/News Update1.jpg",
    link: "#",
  },
  {
    title:
      "L'Union européenne étend son soutien à la sécurité maritime dans la région AfOA-OI",
    description:
      "L'UE renouvelle son engagement en faveur du programme MASE, qui finance des initiatives visant à renforcer la sécurité maritime et à lutter contre les activités illégales en Afrique orientale et australe et dans la région de l'océan Indien.",
    imageUrl: "/rcoc/news/News Update2.jpg",
    link: "#",
  },
  {
    title:
      "Le CRFIM étend sa surveillance pour lutter contre la pêche illégale dans les eaux de l'AOOA-AO",
    description:
      "Grâce à de nouvelles ressources et à une technologie améliorée, le Centre régional de fusion d'informations maritimes intensifie ses efforts de surveillance pour lutter contre la pêche illégale, préserver les écosystèmes marins et soutenir les économies locales.",
    imageUrl: "/rcoc/news/News Update3.jpg",
    link: "#",
  },
  {
    title:
      "Les partenaires internationaux unissent leurs forces aux Seychelles pour lutter contre les menaces maritimes",
    description:
      "Les Seychelles organisent une réunion stratégique avec des parties prenantes internationales pour renforcer la coopération et lutter contre la piraterie, le trafic et d'autres crimes maritimes dans la région AFOA-OI.",
    imageUrl: "/rcoc/news/News Update4.jpg",
    link: "#",
  },
  {
    title:
      "Le CRFIM lance un réseau de partage d'informations en temps réel pour la sécurité régionale",
    description:
      "Une nouvelle initiative du CRFIM permet l'échange de données en temps réel entre les pays de l'ESA-IO, permettant des réponses plus rapides aux menaces maritimes et renforçant la sécurité sur les voies de navigation critiques.",
    imageUrl: "/rcoc/news/News Update5.jpg",
    link: "#",
  },
];

export default LatestNewsDataFR;
