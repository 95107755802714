const SliderData = [
  {
    id: 1,
    image: "/home/home1.png",
    alt: "RCOCRMIFC",
    text: "Le CRFIM lance un nouveau système de surveillance basé sur l'IA pour améliorer la sécurité maritime dans l'océan Indien africain",
    link: "/rmfic1",
  },
  {
    id: 2,
    image: "/home/home2.png",
    alt: "RCOCRMIFC",
    text: "Collaboration historique : le CRFIM et ses partenaires régionaux luttent avec succès contre la pêche illégale dans le cadre d'une opération conjointe",
    link: "/rmfic2",
  },
  {
    id: 3,
    image: "/home/home3.png",
    alt: "RCOCRMIFC",
    text: "Victoire environnementale : le rapport du CRFIM met en évidence une réduction de 20 % de la pollution marine au cours de l'année écoulée",
    link: "/rmfic13",
  },
];

export default SliderData;
