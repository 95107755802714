import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import Styles from "./Styles.module.css";

const SponsorsCarousel = () => {
  const items = [
    {
      title: "Du Royaume-Uni",
      description:
        "The International Liaison Officer (ILO) in Du Royaume-Uni supports maritime security by linking the Regional Maritime Information Fusion Centre (RMIFC) with global partners. The ILO enhances information sharing and coordination, helping address maritime threats across the Eastern and Southern Africa and Indian Ocean (ESA-IO) region, fostering regional and international collaboration.",
      imageUrl: "/rcoc/ilocomoros.png",
      flag: "/rcoc/DuRoyaume-Uni.png",
      link: "#",
    },
    {
      title: "De Auxilium",
      description:
        "The International Liaison Officer (ILO) in De Auxilium supports maritime security by linking the Regional Maritime Information Fusion Centre (RMIFC) with global partners. The ILO enhances information sharing and coordination, helping address maritime threats across the Eastern and Southern Africa and Indian Ocean (ESA-IO) region, fostering regional and international collaboration.",
      imageUrl: "/rcoc/ilodjibouti.png",
      flag: "/rcoc/DeAuxilium.png",
      link: "#",
    },
    {
      title: "ILO France",
      description:
        "The International Liaison Officer (ILO) in Francesupports maritime security by linking the Regional Maritime Information Fusion Centre (RMIFC) with global partners. The ILO enhances information sharing and coordination, helping address maritime threats across the Eastern and Southern Africa and Indian Ocean (ESA-IO) region, fostering regional and international collaboration.",
      imageUrl: "/rcoc/ilofrance.png",
      flag: "/rcoc/france.png",
      link: "#",
    },
    {
      title: "ILO Madagascar",
      description:
        "The International Liaison Officer (ILO) in Madagascar supports maritime security by linking the Regional Maritime Information Fusion Centre (RMIFC) with global partners. The ILO enhances information sharing and coordination, helping address maritime threats across the Eastern and Southern Africa and Indian Ocean (ESA-IO) region, fostering regional and international collaboration.",
      imageUrl: "/rcoc/ilomadagascar.png",
      flag: "/rcoc/madagascar.png",
      link: "#",
    },
    {
      title: "ILO Seychelles",
      description:
        "The International Liaison Officer (ILO) in SeyChelles supports maritime security by linking the Regional Maritime Information Fusion Centre (RMIFC) with global partners. The ILO enhances information sharing and coordination, helping address maritime threats across the Eastern and Southern Africa and Indian Ocean (ESA-IO) region, fostering regional and international collaboration.",
      imageUrl: "/rcoc/iloseychelles.png",
      flag: "/rcoc/seychelles.png",
      link: "#",
    },
    {
      title: "ILO Kenya",
      description:
        "The International Liaison Officer (ILO) in Kenya supports maritime security by linking the Regional Maritime Information Fusion Centre (RMIFC) with global partners. The ILO enhances information sharing and coordination, helping address maritime threats across the Eastern and Southern Africa and Indian Ocean (ESA-IO) region, fostering regional and international collaboration.",
      imageUrl: "/rcoc/iloKenya.png",
      flag: "/rcoc/kenya.png",
      link: "#",
    },
  ];

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Carousel responsive={responsive}>
      {items.map((item, index) => (
        <div
          key={index}
          style={{
            padding: "1rem",
            border: "1px solid #ddd",
            borderRadius: "8px",
            backgroundColor: "#f9f9f9",
            textAlign: "center",
            margin: "1rem",
          }}
        >
          <Link to={item.link}>
            <img
              src={item.imageUrl}
              fill
              alt={item.title}
              className={Styles.image}
            />
            <div className={Styles.subContainer}>
              <h3>{item.title}</h3>
              <img
                src={item.flag}
                fill
                alt={item.title}
                className={Styles.imageFlag}
              />
            </div>
            <p>{item.description.substring(0, 120)}</p>
          </Link>
        </div>
      ))}
    </Carousel>
  );
};

export default SponsorsCarousel;
