import React from "react";
import Styles from "./Styles.module.css";
import { Link } from "react-router-dom";

import SkeletonDisplay from "../../hooks/skeletonDisplay";
import MenuLinks from "./navbar/MenuLinks";
import Footer from "../footer/footer";
import Header from "../header/header";

function NotFoundComp() {
  return (
    <div>
      <div className={Styles.slider1}>
        <div className={Styles.mainContainer}>
          <Header />
          <MenuLinks />
          <SkeletonDisplay>
            <img
              src="/rcoc/aboutus.png"
              fill
              alt="Regional CoordinationOperations Centre (RCOC)"
              className={Styles.image}
            />
          </SkeletonDisplay>
          <div className={Styles.notFound}>
            <h3>404 | Resource Not Found</h3>

            <Link to="/">Return Home</Link>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default NotFoundComp;
