import React from "react";
import Styles from "./Styles.module.css";

function AboutUsComp() {
  return (
    <div className={Styles.mainContainer}>
      <div className={Styles.left}>
        <div className={Styles.menu}>
          {menuItems.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </div>
      </div>
      <div className={Styles.right}>
        <h3>About Us</h3>
        <p>
          The Regional Maritime Information Fusion Centre (RMIFC) is a key
          pillar of the MASE programme, which is supported by funding from the
          European Union. The mission of the IMRFC is to improve maritime safety
          in the Eastern and Southern Africa and Indian Ocean (ESA-IO) region.
          It facilitates the exchange and fusion of maritime information over an
          area stretching from the Cape of Good Hope (South Africa) to the
          Bab-El-Mandeb Strait (Djibouti), thus contributing to the fight
          against illegal activities and crimes committed at sea.
        </p>

        <p>
          The Regional Maritime Information Fusion Centre (RMIFC) is a key
          pillar of the MASE programme, which is supported by funding from the
          European Union. The mission of the IMRFC is to improve maritime safety
          in the Eastern and Southern Africa and Indian Ocean (ESA-IO) region.
          It facilitates the exchange and fusion of maritime information over an
          area stretching from the Cape of Good Hope (South Africa) to the
          Bab-El-Mandeb Strait (Djibouti), thus contributing to the fight
          against illegal activities and crimes committed at sea.
        </p>
      
       
      </div>
    </div>
  );
}

export default AboutUsComp;

const menuItems = [
  "About Us",
  "IOC",
  "Our Teams",
  "Our Partners",
  "TestMonials",
];


