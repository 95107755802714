import React from "react";
import AboutUs from "./homepage/aboutus";
import RCOCDirectors from "./homepage/directors";
import RCOCCoordinator from "./homepage/coordinator";
import RCOCMission from "./homepage/ourmission/rcocmission";
import RCOCMemberStates from "./homepage/memberstate/memberstates";
import RCOCPartnerILO from "./homepage/partnerILO/partnerilo";
import RCOCNEWS from "./homepage/newsbytes/rcocnews";
import RCOCFeaturedStory from "./homepage/featuredstory/featuredstory";
import SignUp from "./signup/signup";
import RCOCMemberStatesFlags from "./homepage/memberstate/memberstatesflags.js";
import RCOCPartnerFlags from "./homepage/partnerILO/partnerflags.js";

const RCOCHomePage = () => {
  return (
    <div>
      <AboutUs />
      <RCOCDirectors />
      <RCOCCoordinator />
      <RCOCMission />
      <RCOCMemberStates />
      <RCOCPartnerILO />
      <RCOCNEWS />
      <RCOCFeaturedStory />
      <RCOCMemberStatesFlags />
      <RCOCPartnerFlags />
      <SignUp />
    </div>
  );
};

export default RCOCHomePage;
