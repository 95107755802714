import React from "react";
import Styles from "./Styles.module.css";
import { Zoom } from "react-awesome-reveal";
import SkeletonDisplay from "../../hooks/skeletonDisplay";

const Mission = () => {
  return (
    <div className={Styles.slider2}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <h1>Our Mission</h1>

            <p>
              The main mission of the RCCO is to plan and coordinate operations
              as defined in Article 6 of the Regional Agreement. Article 6
              stipulates that the missions of regional interest falling within
              the framework of the Agreement include, inter alia, the following
              missions:
            </p>
            {Objectives.map((objective, index) => (
              <li key={index}>{objective}</li>
            ))}

            <div className={Styles.vision}>
              <h1>Our Vision</h1>
              <p>
                To make the CRCO a centre of excellence with autonomous
                administrative, legal and financial management.
              </p>
            </div>
            <div>
              <button>Learn More</button>
            </div>
          </div>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img
                src="/rcoc/ourmission.png"
                alt="Regional Coordination Operations Centre (RCOC)"
              />
            </SkeletonDisplay>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mission;

const Objectives = [
  "Combating maritime piracy, Combating organized crime at sea and the conduct of illegal activities at sea, Assistance in the exercise of the prerogatives of States with regard to ships flying their flag or flying no flag",
  "Participation in the exercise and rescue of human lives at sea, within the framework of existing arrangements",
  "Protection of the marine environment in the event of a foreseeable threat, Participation in the restoration of the safety and security of navigation at sea or in the approaches to seaports, Protection of underwater cultural heritage",
  "Cooperation in the event of natural or environmental disasters.",
];
