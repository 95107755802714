const LatestNewsData = [
  {
    title:
      "Maritime Security Boost: Seychelles Welcomes New International Liaison Officer",
    description:
      "Seychelles strengthens maritime security with the appointment of an International Liaison Officer (ILO) at the Regional Maritime Information Fusion Centre (RMIFC), enhancing global partnerships and fostering regional safety.",
    imageUrl: "/rcoc/news/News Update1.jpg",
    link: "#",
  },
  {
    title:
      "European Union Extends Support for Maritime Safety in ESA-IO Region",
    description:
      "TThe EU renews its commitment to the MASE programme, funding initiatives that bolster maritime security and combat illegal activities in Eastern and Southern Africa and the Indian Ocean region.",
    imageUrl: "/rcoc/news/News Update2.jpg",
    link: "#",
  },
  {
    title:
      "RMIFC Expands Surveillance to Combat Illegal Fishing in ESA-IO Waters",
    description:
      "With new resources and enhanced technology, the Regional Maritime Information Fusion Centre intensifies its surveillance efforts to curb illegal fishing, preserving marine ecosystems and supporting local economies.",
    imageUrl: "/rcoc/news/News Update3.jpg",
    link: "#",
  },
  {
    title:
      "International Partners Join Forces in Seychelles to Tackle Maritime Threats",
    description:
      "Seychelles hosts a strategic meeting with international stakeholders to strengthen cooperation and combat piracy, trafficking, and other maritime crimes in the ESA-IO region.",
    imageUrl: "/rcoc/news/News Update4.jpg",
    link: "#",
  },
  {
    title:
      "RMIFC Launches Real-Time Information Sharing Network for Regional Safety",
    description:
      "A new RMIFC initiative enables real-time data exchange across ESA-IO nations, empowering quicker responses to maritime threats and enhancing security in critical shipping lanes.",
    imageUrl: "/rcoc/news/News Update5.jpg",
    link: "#",
  },
];

export default LatestNewsData;
