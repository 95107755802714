// NavBarData.js
const navLinks = [
  { title: "Home", path: "/" },
  {
    title: "Regional Maritime Information Fusion Centre (RMFIC)",
    path: "/rmifc",
  },
  { title: "Regional Coordination Operations Centre (RCOC)", path: "/rcoc" },
];

export default navLinks;
