import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Styles from "./styles1.module.css";
import { ShowCases } from "./showcaseDatafr";

function MainCenters() {
  const [activity, setActivity] = useState([]);
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    const fetchData = async () => {
      try {
        await delay(2000);
        setActivity(ShowCases);
      } catch (error) {
        console.error("Error fetching themes:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className={Styles.themesContainer}>
      {!activity[0] ? (
        <>
          {data.map((_, index) => (
            <div key={index}></div>
          ))}
        </>
      ) : (
        activity.map((showcase, index) => (
          <div className={Styles.productCard} key={index}>
            <Link to={showcase.link}>
              <h5>{showcase.title}</h5>
              <img
                src={showcase.image}
                fill
                alt="RCOCRFMIC"
                className={Styles.image}
              />
              <p>{showcase.description}</p>
              <div>
                <button>More Information</button>
              </div>{" "}
            </Link>
          </div>
        ))
      )}
    </div>
  );
}

export default MainCenters;
const data = [1, 2, 3, 4];
