"use client";
import React from "react";
import Styles from "./Styles.module.css";
import { Link } from "react-router-dom";

function Header() {
  return (
    <div className={Styles.container}>
      <div className={Styles.sidebar}>
        <Link to="/rmifcfr">
          <img
            src="/header/rmifc.png"
            fill
            alt="RCOCRFMIC"
            className={Styles.image}
          />
        </Link>
      </div>

      <div className={Styles.mainContent}>
        <h1>ARCHITECTURE RÉGIONALE DE SÉCURITÉ MARITIME (RMSA)</h1>
      </div>
      <div className={Styles.sidebar}>
        <Link to="/rcocfr">
          <img
            src="/header/rcoc.png"
            fill
            alt="RCOCRFMIC"
            className={Styles.image}
          />
        </Link>
      </div>
    </div>
  );
}

export default Header;
