import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import Styles from "./Styles.module.css";
import LatestNewsDataFR from "./rcoclatestnewsdatafr";

const NewsCarouselFR = () => {


  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const styleOver = {
    border: "solid #fff 1rem",
  };

  return (
    <Carousel responsive={responsive}>
      {LatestNewsDataFR.map((item, index) => (
        <div
          key={index}
          style={{
            width: "100%",
            padding: "0.25rem",
            border: "1px solid #ddd",
            borderRadius: "8px",
            backgroundColor: "#f9f9f9",
            textAlign: "center",
            onMouseOver: { styleOver },
          }}
        >
          <Link to={item.link}>
            <img
              src={item.imageUrl}
              fill
              alt={item.title}
              className={Styles.image}
            />
            <h3>{item.title}</h3>
            <p>{item.description}</p>
          </Link>
        </div>
      ))}
    </Carousel>
  );
};

export default NewsCarouselFR;
