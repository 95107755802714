import React from "react";
import { Outlet } from "react-router-dom";
import NavBar from "../../rcoc/navbar/navbarfr";
import LanguageBar from "../LanguageBar/languagebarfr";
import FooterComp from "../../rcoc/footer/footercompfr";
import HomeSliderFR from "../../rcoc/slider/homesliderfr";

function RCOCLayout2FR() {
  return (
    <div>
      <LanguageBar />
      <NavBar />
      <HomeSliderFR />
      <div>
        <Outlet />
      </div>
      <FooterComp />
    </div>
  );
}

export default RCOCLayout2FR;
