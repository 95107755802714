import React from "react";
import Styles from "./Styles.module.css";

const OurValues = () => {
  const leftArray = Objectives.slice(0, 5);
  const rightArray = Objectives.slice(6, 10);
  return (
    <div className={Styles.slider2}>
      <div className={Styles.ourvalues}>
        <h1>Nos Valeurs</h1>
      </div>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <div className={Styles.arrayContainer}>
              <table>
                <tr>
                  <td>
                    {leftArray.map((objective, index) => (
                      <div key={index} className={Styles.arrayItem}>
                        <h1>{objective.title.substring(0, 1)}</h1>
                      </div>
                    ))}
                  </td>
                  <td>
                    {leftArray.map((objective, index) => (
                      <div>
                        {" "}
                        <h3>{objective.title}</h3>
                        <p>{objective.description}</p>
                      </div>
                    ))}
                  </td>
                </tr>
              </table>
            </div>

            <div>
              <button>Learn More</button>
            </div>
          </div>
          <div className={Styles.productCard}>
            <div className={Styles.arrayContainer}>
              <table>
                <tr>
                  <td>
                    {rightArray.map((objective, index) => (
                      <div key={index} className={Styles.arrayItem}>
                        <h1>{objective.title.substring(0, 1)}</h1>
                      </div>
                    ))}
                  </td>
                  <td>
                    {rightArray.map((objective, index) => (
                      <div>
                        {" "}
                        <h3>{objective.title}</h3>
                        <p>{objective.description}</p>
                      </div>
                    ))}
                  </td>
                </tr>
              </table>
            </div>

            <div>
              <button>Learn More</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurValues;

const Objectives = [
  {
    title: "Intégrité ",
    description:
      "Respecter les normes les plus élevées d’honnêteté et de comportement éthique dans toutes nos opérations et interactions.",
  },
  {
    title: "Collaboration ",
    description:
      "Favoriser un environnement coopératif qui encourage le travail d’équipe et les partenariats entre les secteurs public, privé et civil.",
  },
  {
    title: "Excellence ",
    description:
      "Rechercher le plus haut niveau de performance et d’amélioration continue dans toutes nos activités et services.",
  },
  {
    title: "Innovation ",
    description:
      "Adopter de nouvelles technologies et méthodologies pour améliorer nos informations maritimes et nos capacités opérationnelles.",
  },
  {
    title: "Transparence  ",
    description:
      "Assurer l’ouverture et une communication claire dans nos processus, nos décisions et le partage d’informations.",
  },
  {
    title: "Responsabilité  ",
    description:
      "Respecter les normes les plus élevées d’honnêteté et de comportement éthique dans toutes nos opérations et interactions.",
  },
  {
    title: "Adaptabilité ",
    description:
      "Être flexible et réactif à la nature dynamique des défis et opportunités maritimes.",
  },
  {
    title: "Professionnalisme ",
    description:
      "Faire preuve de compétence, de fiabilité et de respect dans toutes nos interactions et efforts.",
  },
  {
    title: "Inclusivité ",
    description:
      "Valoriser la diversité des perspectives et promouvoir une participation et une représentation égales dans les activités maritimes.",
  },
  {
    title: "Service ",
    description:
      "Dévouement à servir la communauté maritime et à contribuer au bien-être général et à l’avancement du secteur maritime.",
  },
];
