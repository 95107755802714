import React, { useState } from "react";
import Styles from "./Styles.module.css";
import { East } from "@mui/icons-material";
const SignUp = () => {
  const [formData, setFormData] = useState({
    email: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    // Handle form submission logic here
    console.log("Form submitted:", formData);

    // Reset form fields
    setFormData({
      email: "",
    });

    // Show the success message
    setSubmitted(true);

    // Optionally, hide the message after a few seconds
    setTimeout(() => {
      setSubmitted(false);
    }, 3000); // Hide after 3 seconds
  };
  const isFormDataFilled = formData.email !== "";

  const buttonStyle = {
    backgroundColor: isFormDataFilled ? "var(--primaryColor)" : "grey",
    width: "50%",
    color: "white",
    border: "none",
    padding: "10px 20px",
    cursor: isFormDataFilled ? "pointer" : "not-allowed",
  };
  return (
    <div className={Styles.signUp}>
      <form onSubmit={handleSubmit}>
        <h3>Inscrivez-vous Pour Nos Dernières Nouvelles</h3>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <p>
              Gardez une longueur d'avance dans les opérations maritimes.
              Abonnez-vous au RCOC pour recevoir en temps opportun mises à jour,
              analyses d’experts et dernières informations maritimes. Rejoignez
              notre communauté et améliorez votre prise de décision maritime
              aujourd'hui!
            </p>
          </div>

          <div className={Styles.productCard}>
            <input
              placeholder="Entrez votre adresse e-mail"
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className={Styles.signUpSubcontainer}
            />
          </div>
          <div className={Styles.productCard}>
            <button
              type="submit"
              disabled={!isFormDataFilled}
              style={buttonStyle}
            >
              S'abonner
              <East />
            </button>
          </div>
        </div>
      </form>
      {submitted && (
        <div className={Styles.submittedForm}>
          Merci, soumis avec succès
        </div>
      )}
    </div>
  );
};

export default SignUp;
