import React from "react";
import Styles from "./Styles.module.css";

function AboutUsComp() {
  return (
    <div className={Styles.mainContainer}>
      <div className={Styles.left}>
        <div className={Styles.menu}>
          {menuItems.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </div>
      </div>
      <div className={Styles.right}>
        <h3>À propos de nous</h3>
        <p>
          Le Centre Régional de Fusion d'Informations Maritimes (CRFIM) est un
          pilier clé du programme MASE, soutenu par un financement de l'Union
          Européenne. La mission de l'IMRFC est d'améliorer la sécurité maritime
          dans la région de l'Afrique orientale et australe et de l'océan Indien
          (ESA-IO). Il facilite l'échange et la fusion des informations
          maritimes sur une zone s'étendant du cap de Bonne-Espérance (Afrique
          du Sud) jusqu'au détroit de Bab-El-Mandeb (Djibouti), contribuant
          ainsi à la lutte contre les activités illégales et les crimes commis
          en mer.
        </p>
        <p>
          Le Centre Régional de Fusion d'Informations Maritimes (CRFIM) est un
          pilier clé du programme MASE, soutenu par un financement de l'Union
          Européenne. La mission de l'IMRFC est d'améliorer la sécurité maritime
          dans la région de l'Afrique orientale et australe et de l'océan Indien
          (ESA-IO). Il facilite l'échange et la fusion des informations
          maritimes sur une zone s'étendant du cap de Bonne-Espérance (Afrique
          du Sud) jusqu'au détroit de Bab-El-Mandeb (Djibouti), contribuant
          ainsi à la lutte contre les activités illégales et les crimes commis
          en mer.
        </p>
      </div>
    </div>
  );
}

export default AboutUsComp;

const menuItems = [
  "À propos de nous",
  "CIO",
  "Nos équipes",
  "Nos partenaires",
  "TestMonials",
];
