import React from "react";
import Styles from "./Styles.module.css";
import SkeletonDisplay from "../../../hooks/skeletonDisplay";
import { Link } from "react-router-dom";

const RMIFCFeaturedStoryFR = () => {
  return (
    <div className={Styles.mainContainer}>
      <div className={Styles.container}>
        <div className={Styles.productCard}>
          <SkeletonDisplay>
            <img
              src="/rcoc/news/featuredstory.jpg"
              alt="Regional Coordination Operations Centre (RCOC)"
            />
          </SkeletonDisplay>
        </div>

        <div className={Styles.productCard}>
          <h1>
            Sécuriser les mers : comment la fusion des informations maritimes
            régionales Le centre transforme la sécurité maritime dans l’océan
            Indien
          </h1>

          <p>
            Dans les eaux vastes et souvent vulnérables de l'océan Indien, la
            sécurité maritime est confrontée à des défis sans précédent. Du
            piratage et la traite des êtres humains, la pêche illégale et les
            menaces environnementales, ces problèmes mettent à rude épreuve les
            ressources de la région et ont un impact sur le commerce mondial et
            la stabilité économique. À l’avant-garde de la solution se trouve
            Centre Régional de Fusion d'Informations Maritimes (CRFIM), un outil
            crucial pilier du programme de sécurité maritime (MASE) soutenu par
            l'UE, dédié à rendre ces eaux plus sûres.
          </p>
          <p>
            Implanté aux Seychelles, le CRFIM est une plateforme de suivi
            activité maritime sur une vaste zone allant du Cap de Bonne Espoir
            en Afrique du Sud jusqu'au détroit de Bab-El-Mandeb près de
            Djibouti. Il fonctionne sur un modèle sophistiqué de fusion de
            données et d’informations échanger, collecter et analyser des
            données en temps réel pour aider les membres les nations réagissent
            rapidement aux menaces. Le rôle du CRFIM s’étend au-delà
            surveillance; il se coordonne avec les agences nationales et
            internationales organisations, renforçant la réponse régionale et
            internationale aux délits maritimes.
          </p>

          <div>
            <Link to="/aboutusrcoc">
              <button>En savoir plus</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RMIFCFeaturedStoryFR;
