import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import Styles from "./Styles.module.css";

const SponsorsCarouselFlags = () => {
  const items = [
    {
      title: "Comoros",
      description:
        "The International Liaison Officer (ILO) in Comoros supports maritime security by linking the Regional Maritime Information Fusion Centre (RMIFC) with global partners. The enhances information sharing and coordination, helping address maritime threats across the Eastern and Southern Africa and Indian Ocean (ESA-IO) region, fostering regional and international collaboration.",
      imageUrl: "/rcoc/ilocomoros.png",
      flag: "/rcoc/comoros.png",
      link: "#",
    },
    {
      title: "Djibouti",
      description:
        "The International Liaison Officer (ILO) in Djibouti supports maritime security by linking the Regional Maritime Information Fusion Centre (RMIFC) with global partners. The enhances information sharing and coordination, helping address maritime threats across the Eastern and Southern Africa and Indian Ocean (ESA-IO) region, fostering regional and international collaboration.",
      imageUrl: "/rcoc/ilodjibouti.png",
      flag: "/rcoc/djibouti.png",
      link: "#",
    },
    {
      title: "France",
      description:
        "The International Liaison Officer (ILO) in Francesupports maritime security by linking the Regional Maritime Information Fusion Centre (RMIFC) with global partners. The enhances information sharing and coordination, helping address maritime threats across the Eastern and Southern Africa and Indian Ocean (ESA-IO) region, fostering regional and international collaboration.",
      imageUrl: "/rcoc/ilofrance.png",
      flag: "/rcoc/france.png",
      link: "#",
    },
    {
      title: "Madagascar",
      description:
        "The International Liaison Officer (ILO) in Madagascar supports maritime security by linking the Regional Maritime Information Fusion Centre (RMIFC) with global partners. The enhances information sharing and coordination, helping address maritime threats across the Eastern and Southern Africa and Indian Ocean (ESA-IO) region, fostering regional and international collaboration.",
      imageUrl: "/rcoc/ilomadagascar.png",
      flag: "/rcoc/madagascar.png",
      link: "#",
    },
    {
      title: "Seychelles",
      description:
        "The International Liaison Officer (ILO) in SeyChelles supports maritime security by linking the Regional Maritime Information Fusion Centre (RMIFC) with global partners. The enhances information sharing and coordination, helping address maritime threats across the Eastern and Southern Africa and Indian Ocean (ESA-IO) region, fostering regional and international collaboration.",
      imageUrl: "/rcoc/iloseychelles.png",
      flag: "/rcoc/seychelles.png",
      link: "#",
    },
    {
      title: "Kenya",
      description:
        "The International Liaison Officer (ILO) in Kenya supports maritime security by linking the Regional Maritime Information Fusion Centre (RMIFC) with global partners. The enhances information sharing and coordination, helping address maritime threats across the Eastern and Southern Africa and Indian Ocean (ESA-IO) region, fostering regional and international collaboration.",
      imageUrl: "/rcoc/iloKenya.png",
      flag: "/rcoc/kenya.png",
      link: "#",
    },
  ];

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
    },
  };

  const styleOver = {
    border: "solid #fff 1rem",
  };

  return (
    <Carousel responsive={responsive}>
      {items.map((item, index) => (
        <div
          key={index}
          style={{
            padding: "0.5rem",
            border: "1px solid #ddd",
            borderRadius: "8px",
            backgroundColor: "#f9f9f9",
            textAlign: "center",
            margin: "1rem",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            onMouseOver: { styleOver },
          }}
        >
          <Link to={item.link}>
            <img
              src={item.flag}
              fill
              alt={item.title}
              className={Styles.image}
            />
            <h5>{item.title}</h5>
          </Link>
        </div>
      ))}
    </Carousel>
  );
};

export default SponsorsCarouselFlags;
