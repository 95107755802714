import React, { useState, useEffect } from "react";
import Styles from "./Styles.module.css";
import CopyRight from "./copyright";
import { Zoom } from "react-awesome-reveal";
import { Link } from "react-router-dom";

const FooterComp = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const toggleVisible = () => {
      const scrolled = document.documentElement.scrollTop;
      setVisible(scrolled > 100);
    };

    window.addEventListener("scroll", toggleVisible);
    return () => window.removeEventListener("scroll", toggleVisible);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className={Styles.slider8}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container8}>
          <div className={Styles.productCard}>
            <Zoom>
              <Link to="/">
                <img
                  src="/header/rmifc.png"
                  alt="Regional Maritime Information Fusion Centre (RMFIC)"
                  className={Styles.image}
                />
              </Link>
              <h4>
                We Organize joint and/or coordinated interventions at sea and in the surrounding space to prevent and stop acts or situations affecting maritime safety and security
              </h4>
            </Zoom>
          </div>
          <div className={Styles.productCard}>
            <h4>Quick Links</h4>
            {navLinks.map((menulink, index) => (
              <li key={index}>
                <a
                  href={menulink.path}
                  style={{
                    paddingTop: "-2rem",
                  }}
                  onClick={scrollToTop}
                >
                  {menulink.title}
                </a>
              </li>
            ))}
          </div>
          <div className={Styles.productCard}>
            <h4>Key Links</h4>
            {generalLinks.map((menulink, index) => (
              <li key={index}>
                <a
                  href={menulink.path}
                  style={{
                    paddingTop: "-2rem",
                  }}
                  onClick={scrollToTop}
                >
                  {menulink.title}
                </a>
              </li>
            ))}
          </div>
          <div className={Styles.productCard}>
            <h4>Get In Touch</h4>
            <div className={Styles.contacts}>
              <h4>Email:</h4>
              <p>info@rmifc.org </p>
            </div>
            <div className={Styles.contacts}>
              <h3>Call us:</h3>
              <p>+ 333 000 000 000 </p>
              <p>+ 333 000 000 000 </p>
            </div>
            <div className={Styles.contacts}>
              <h3>Address</h3>
              <p>P O Box : 1247 Ex-Coast Guard </p>
              <p>Bois de Rose | Victoria | Mahe | Seychelles</p>
            </div>
          </div>
        </div>
        <div>
          <CopyRight />
        </div>
      </div>
    </div>
  );
};

export default FooterComp;

const navLinks = [
  { title: "About Us", path: "/About Us" },
  { title: "Media Center", path: "/Media Center" },
  { title: "Resources", path: "/Resources" },
  { title: "Events", path: "/Events" },
  { title: "Blog", path: "/Blog" },
];

const generalLinks = [
  { title: "Regional Agreements", path: "/Regional Agreements" },
  { title: "Whitepaper", path: "/Whitepapers" },
  { title: "Case Studies", path: "/Case Studies" },
  { title: "Info Graphics", path: "/Info Graphics" },
  { title: "Upcoming Events", path: "/Upcoming Events" },
];
