import React from "react";
import AboutUsComp from "./aboutuscomp";
import Mission from "../Our Mission/mission";
import ThematicAreas from "../thematicareas/thematicareas";
import OurValues from "../ourvalues/ourvalues";
import SignUp from "../signup/signup";

const RMIFCAboutUsIndex = () => {
  return (
    <div>
      <AboutUsComp />
      <Mission />
      <ThematicAreas />
      <OurValues />
      <SignUp />
    </div>
  );
};

export default RMIFCAboutUsIndex;
