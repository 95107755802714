import React from "react";
import Header from "./components/header/header";
import HomeSlider from "./components/homeslider/homeslider";
import MainCenters from "./components/centers/maincenters";
import Sponsors from "./components/sponsors/sponsors";
import Footer from "./components/footer/footer";

const Home = () => {
  return (
    <div>
      <Header />
      <HomeSlider />
      <MainCenters />
      <Sponsors />
      <Footer/>
    </div>
  );
};

export default Home;
