import SponsorsCarouselFR from "./sponsorscarouselfr";
import React from "react";

function RCOCMemberStatesFR() {
  return (
    <div style={{ margin: "3rem" }}>
      <div>
        <h3
          style={{
            fontSize: "2rem",
            textAlign: "center",
            color: "var(--primaryColor)",
            fontWeight: "bolder",
          }}
        >
          Nos États membres OIT
        </h3>
        <div>
          <SponsorsCarouselFR />
        </div>
      </div>
    </div>
  );
}

export default RCOCMemberStatesFR;
