import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import Styles from "./Styles.module.css";

const SponsorsCarousel = () => {
  const items = [
    {
      title: "Indian Ocean Commisssion",
      description: "Indian Ocean Commisssion",
      imageUrl: "/sponsors/ioc.png",
      link: "IndianOceanCommisssion",
    },
    {
      title: "European Commission",
      description: "European Commission",
      imageUrl: "/sponsors/eu.png",
      link: "European Commission",
    },
    {
      title: "Combined Maritime Forces",
      description: "Combined Maritime Forces",
      imageUrl: "/sponsors/combinedmaritimeforces.png",
      link: "Combined Maritime Forces",
    },
    {
      title: "EU Crimario",
      description: "EU Crimario",
      imageUrl: "/sponsors/eucrimario.png",
      link: "EU Crimario",
    },
    {
      title: "IORIS",
      description: "IORIS",
      imageUrl: "/sponsors/ioris.png",
      link: "ioris",
    },
    {
      title: "Indian Ocean Commisssion",
      description: "Indian Ocean Commisssion",
      imageUrl: "/sponsors/ioc.png",
      link: "IndianOceanCommisssion",
    },
    {
      title: "European Commission",
      description: "European Commission",
      imageUrl: "/sponsors/eu.png",
      link: "European Commission",
    },
  ];

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items:1,
    },
  };

  return (
    <Carousel responsive={responsive}>
      {items.map((item, index) => (
        <div
          key={index}
          style={{
            padding: "1rem",
            border: "1px solid #ddd",
            borderRadius: "8px",
            backgroundColor: "#f9f9f9",
            textAlign: "center",
            margin: "1rem",
          }}
        >
          <Link to={item.link}>
            <img
              src={item.imageUrl}
              fill
              alt={item.title}
              className={Styles.image}
            />
          </Link>
        </div>
      ))}
    </Carousel>
  );
};

export default SponsorsCarousel;
