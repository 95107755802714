import AppRouter from "./approuter";
import { Content } from "./hooks/scrollbuttonstyles";
import ScrollButton from "./hooks/scrollbutton";
import CookieConsent from "./hooks/cookies";

function App() {
  return (
    <div>
      <AppRouter />
      <Content />
      <ScrollButton />
      <CookieConsent />
    </div>
  );
}

export default App;
