// NavBar.js
"use client";
import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import { Link } from "react-router-dom";
import navLinks from "./NavBarData";
import navLinksMobile from "./NavBarDatamobile";
import "./NavBar.css";
import { ArrowDropDown } from "@mui/icons-material";

const MenuLinks = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 1) {
      setVisible(true);
    } else if (scrolled <= 1) {
      setVisible(false);
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const TitleLink = ({ link }) => {
    return (
      <div style={{ display: "flex" }}>
        {link}{" "}
        <div style={{ marginTop: "0.2rem" }}>
          <ArrowDropDown />
        </div>
      </div>
    );
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour 
      in place of 'smooth' */
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <nav className="navbar">
      <div className="logoDisplayMinimized">
        <Link to="/">
          <img
            src="/rcoc/rcocmobilelogo.png"
            fill
            alt="Regional Coordination Operations Centre (RCOC)"
            className="image"
          />
        </Link>
      </div>
      <div className="menu-icon" onClick={toggleMenu}>
        <FaBars />
      </div>
      <div className="nav-title">
        <img
          src="/header/rcoc.png"
          fill
          alt="REGIONAL MARITIME SECURITY ARCHITECTURE (RMSA)"
          className="image"
          style={{ display: visible ? "inline" : "none" }}
        />
        <h5 style={{ display: visible ? "inline" : "none" }}>
          REGIONAL MARITIME SECURITY ARCHITECTURE (RMSA)
        </h5>
      </div>
      <ul className={`nav-links ${menuOpen ? "active" : ""}`}>
        {navLinks.map((link, index) => (
          <li key={index} onMouseLeave={closeMenu}>
            <Link onClick={scrollToTop} to={link.path}>
              {link.subLinks ? <TitleLink link={link.title} /> : link.title}
            </Link>
            {link.subLinks && (
              <ul className="sub-links">
                {link.subLinks.map((subLink, subIndex) => (
                  <li key={subIndex}>
                    <Link to={`/${subLink.toLowerCase()}`}>{subLink}</Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
      <ul className={`nav-linksmobile ${menuOpen ? "active" : ""}`}>
        {navLinksMobile.map((menu, index) => (
          <li key={index}>{menu.title}</li>
        ))}
      </ul>
    </nav>
  );
};

export default MenuLinks;
