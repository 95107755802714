import React from "react";
import Styles from "./Styles.module.css";

const RCOCMission = () => {
  return (
    <div className={Styles.signUp}>
      <h2>Our Mission</h2>
      <div className={Styles.container}>
        <div className={Styles.productCard}>
          <h3>
            Collect, merge, analyse and share maritime information relating to:
          </h3>
          <p>
            Violence at sea, IUU Fishing, Human trafficking at sea, Traffick and
            contraband at sea, Weapons proliferation and security initiative,
            Maritime incident, Natural event, Maritime cybercrime, Critical
            infrastructure, Yachting and maritime tourism, Marine environment
          </p>
        </div>
      </div>
    </div>
  );
};

export default RCOCMission;
