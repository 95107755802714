const ShowCases = [
  {
    center1: "1",
    title: "Centre Régional de Fusion d'Informations Maritimes (CRFIM) ",
    image: "/rmifc/home.png",
    description:
      "Facilite l'échange et la fusion des informations maritimes sur une zone s'étendant du Cap de Bonne-Espérance (Afrique du Sud) jusqu'au détroit de Bab-El-Mandeb (Djibouti), contribuant ainsi à la lutte contre les activités illégales et les crimes commis en mer.",
    link: "/rmifcfr",
  },
  {
    center2: "2",
    title: "Centre régional des opérations de coordination (RCCO)",
    image: "/rcoc/home.png",
    description:
      "Facilite l'échange et la fusion des informations maritimes sur une zone s'étendant du Cap de Bonne-Espérance (Afrique du Sud) jusqu'au détroit de Bab-El-Mandeb (Djibouti), contribuant ainsi à la lutte contre les activités illégales et les crimes commis en mer.",
    link: "/rcocfr",
  },
];

export { ShowCases };
