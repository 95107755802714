import React, { useState, useEffect } from "react";
import Styles from "./Styles.module.css";
import CopyRight from "./copyrightfr";
import { Zoom } from "react-awesome-reveal";
import { Link } from "react-router-dom";

const FooterComp = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const toggleVisible = () => {
      const scrolled = document.documentElement.scrollTop;
      setVisible(scrolled > 100);
    };

    window.addEventListener("scroll", toggleVisible);
    return () => window.removeEventListener("scroll", toggleVisible);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className={Styles.slider8}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container8}>
          <div className={Styles.productCard}>
            <Zoom>
              <Link to="/">
                <img
                  src="/header/rcoc.png"
                  alt="Regional Coordination Operations Centre (RCOC)"
                  className={Styles.image}
                />
              </Link>
              <h4>
                Nous organisons des interventions conjointes et/ou coordonnées
                en mer et dans l'espace environnant pour prévenir et faire
                cesser les actes ou situations affectant la sûreté et la
                sécurité maritimes.
              </h4>
            </Zoom>
          </div>
          <div className={Styles.productCard}>
            <h4>Liens rapides</h4>
            {navLinks.map((menulink, index) => (
              <li key={index}>
                <a
                  href={menulink.path}
                  style={{
                    paddingTop: "-2rem",
                  }}
                  onClick={scrollToTop}
                >
                  {menulink.title}
                </a>
              </li>
            ))}
          </div>
          <div className={Styles.productCard}>
            <h4>Liens clés</h4>
            {generalLinks.map((menulink, index) => (
              <li key={index}>
                <a
                  href={menulink.path}
                  style={{
                    paddingTop: "-2rem",
                  }}
                  onClick={scrollToTop}
                >
                  {menulink.title}
                </a>
              </li>
            ))}
          </div>
          <div className={Styles.productCard}>
            <h4>Entrer en contact</h4>
            <div className={Styles.contacts}>
              <h4>E-mail:</h4>
              <p>info@rcoc.org </p>
            </div>
            <div className={Styles.contacts}>
              <h3>Appelez-nous :</h3>
              <p>(+248) 430 38 77 </p>
              <p>(+248) 262 84 66 </p>
            </div>
            <div className={Styles.contacts}>
              <h3>Adresse</h3>
              <p>P O Box : 1247 Ex-Coast Guard </p>
              <p>Bois de Rose | Victoria | Mahe | Seychelles</p>
            </div>
          </div>
        </div>
        <div>
          <CopyRight />
        </div>
      </div>
    </div>
  );
};

export default FooterComp;

const navLinks = [
  { title: "À propos de nous", path: "/À propos de nous" },
  { title: "", path: "/Centre des médias" },
  { title: "Ressources", path: "/Ressources" },
  { title: "Événements", path: "/Events" },
  { title: "Blogue", path: "/Blog" },
];

const generalLinks = [
  { title: "Accords régionaux", path: "/Accords régionaux" },
  { title: "Livre blanc", path: "/Livre blancs" },
  { title: "Études de cas", path: "/Études de cas" },
  { title: "Infographie", path: "/Infographie" },
  { title: "Événements à venir", path: "/Événements à venir" },
];
