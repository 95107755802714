import SponsorsCarousel from "./sponsorscarousel";
import React from "react";
import Styles from "./Styles.module.css";

function Sponsors() {
  return (
    <div style={{ margin: "1rem" }}>
      <div>
        <h3
          style={{
            fontSize: "2rem",
            textAlign: "center",
            color: "var(--primaryColor)",
            fontWeight: "bolder",
          }}
        >
          Our Sponsors
        </h3>
        <div>
          <SponsorsCarousel />
        </div>
        <div className={Styles.container}>
          <h4>Website Sponsored by:</h4>
          <img
            src="/sponsors/unicea.png"
            fill
            alt="RCOCRFMIC"
            className={Styles.image}
          />
        </div>
      </div>
    </div>
  );
}

export default Sponsors;
