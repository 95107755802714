import React from "react";
import Styles from "./Styles.module.css";
import { Zoom } from "react-awesome-reveal";
import SkeletonDisplay from "../../hooks/skeletonDisplay";

const Mission = () => {
  return (
    <div className={Styles.slider2}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <h1>Notre Mission</h1>

            <p>
              La mission principale du RCCO est de planifier et de coordonner
              les opérations tel que défini à l’article 6 de l’accord régional.
              Article 6 précise que les missions d'intérêt régional relevant du
              le cadre de l'accord comprend, entre autres, les éléments suivants
              missions :
            </p>
            {Objectives.map((objective, index) => (
              <li key={index}>{objective}</li>
            ))}

            <div className={Styles.vision}>
              <h1>Notre Vision</h1>
              <p>
                Faire du CRCO un pôle d’excellence doté d’autonomie gestion
                administrative, juridique et financière.
              </p>
            </div>
            <div>
              <button>Learn More</button>
            </div>
          </div>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img
                src="/rcoc/ourmission.png"
                alt="Centre régional des opérations de coordination (RCOC)"
              />
            </SkeletonDisplay>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mission;

const Objectives = [
 "Lutte contre la piraterie maritime, Lutte contre la criminalité organisée en mer et la conduite d'activités illégales en mer, Assistance à l'exercice des prérogatives des Etats à l'égard des navires battant leur pavillon ou sans pavillon",
  "Participation à l'exercice et au sauvetage de vies humaines en mer, dans le cadre des dispositifs existants",
  "Protection du milieu marin en cas de menace prévisible, Participation au rétablissement de la sûreté et de la sécurité de la navigation en mer ou aux abords des ports maritimes, Protection du patrimoine culturel subaquatique",
  "Coopération en cas de catastrophes naturelles ou environnementales.",
];
