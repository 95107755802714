import React from "react";
import Styles from "./Styles.module.css";
import SkeletonDisplay from "../../../hooks/skeletonDisplay";
import { Link } from "react-router-dom";

const RCOCFeaturedStory = () => {
  return (
    <div className={Styles.mainContainer}>
      <div className={Styles.container}>
        <div className={Styles.productCard}>
          <SkeletonDisplay>
            <img
              src="/rcoc/news/featuredstory.jpg"
              alt="Regional Coordination Operations Centre (RCOC)"
            />
          </SkeletonDisplay>
        </div>

        <div className={Styles.productCard}>
          <h1>
            Securing the Seas: How the Regional Maritime Information Fusion
            Centre is Transforming Maritime Safety in the Indian Ocean
          </h1>

          <p>
            In the vast and often vulnerable waters of the Indian Ocean,
            maritime security faces unprecedented challenges. From piracy and
            human trafficking to illegal fishing and environmental threats,
            these issues strain the region's resources and impact global trade
            and economic stability. At the forefront of the solution is the
            Regional Maritime Information Fusion Centre (RMIFC), a crucial
            pillar of the EU-backed Maritime Security (MASE) programme,
            dedicated to making these waters safer.
          </p>
          <p>
            Established in Seychelles, the RMIFC is a hub for monitoring
            maritime activity across an extensive area from the Cape of Good
            Hope in South Africa to the Bab-El-Mandeb Strait near Djibouti. It
            operates on a sophisticated model of data fusion and information
            exchange, collecting and analyzing real-time data to help member
            nations respond swiftly to threats. The RMIFC’s role extends beyond
            monitoring; it coordinates with national agencies and international
            organizations, strengthening the regional and international response
            to maritime crimes.
          </p>

          <div>
            <Link to="/aboutusrcoc">
              <button>Read More</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RCOCFeaturedStory;
