// NavBarData.js
const navLinks = [
  { title: "Home", path: "/rcoc" },

  {
    title: "About",
    path: "/#",
    subLinks: [
      "À propos de nous",
      "Directeur",
      "CIO",
      "Nos équipes",
      "Nos partenaires",
      "Témoignages",
    ],
  },
  {
    title: "Notre travail",
    path: "/Notre travail",
    subLinks: [
      "Renforcement des capacités",
      "Coordination des opérations régionales en mer",
      "Exercices régionaux sur table (TTX)",
      "Exercices d'entraînement sur le terrain (FTX) Réalisations du RCOC",
    ],
  },
  {
    title: "Centre des médias",
    path: "/Centre des médias",
    subLinks: ["Actualités", "Blog", "Galerie", "Vidéos"],
  },
  {
    title: "Ressources",
    path: "/Ressources",
    subLinks: [
      "Etudes de cas",
      "Rapports généraux",
      "Articles de recherche",
      "Livres blancs",
    ],
  },
  { title: "Événements", path: "/Événements" },
  {
    title: "Cadre de gouvernance",
    path: "/Cadre de gouvernance",
    subLinks: [
      "Accords régionaux",
      "Conseil des ministres",
      "Comité de pilotage",
      "États signataires",
      "Fonds fiduciaire",
      "Secrétariat",
    ],
  },
  { title: "Contactez-nous", path: "/Contactez-nous" },
];

export default navLinks;
