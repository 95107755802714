import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import Styles from "./Styles.module.css";

const SponsorsCarouselFR = () => {
  const items = [
    {
      title: "Du Royaume-Uni",
      description:
        "L'officier de liaison internationale (OIT) du Royaume-Uni soutient la sécurité maritime en reliant le Centre régional de fusion d'informations maritimes (CRFIM) avec des partenaires mondiaux. L'OIT améliore le partage d'informations et la coordination, en aidant à faire face aux menaces maritimes dans la région de l'Afrique orientale et australe et de l'océan Indien (ESA-IO), en favorisant la collaboration régionale et internationale.",
      imageUrl: "/rcoc/ilocomoros.png",
      flag: "/rcoc/DuRoyaume-Uni.png",
      link: "#",
    },
    {
      title: "De Auxilium",
      description:
        "L'officier de liaison internationale (OIT) de De Auxilium soutient la sécurité maritime en reliant le Centre régional de fusion d'informations maritimes (CRFIM) avec des partenaires mondiaux. L'OIT améliore le partage d'informations et la coordination, en aidant à faire face aux menaces maritimes dans la région de l'Afrique orientale et australe et de l'océan Indien (ESA-IO), en favorisant la collaboration régionale et internationale.",
      imageUrl: "/rcoc/ilodjibouti.png",
      flag: "/rcoc/DeAuxilium.png",
      link: "#",
    },
    {
      title: "France",
      description:
        "L'officier de liaison internationale (OIT) en France soutient la sécurité maritime en reliant le Centre régional de fusion d'informations maritimes (CRFIM) avec des partenaires mondiaux. L'OIT améliore le partage d'informations et la coordination, en aidant à faire face aux menaces maritimes dans la région de l'Afrique orientale et australe et de l'océan Indien (ESA-IO), en favorisant la collaboration régionale et internationale.",
      imageUrl: "/rcoc/ilofrance.png",
      flag: "/rcoc/france.png",
      link: "#",
    },
    {
      title: "Madagascar",
      description:
        "L'officier de liaison internationale (OIT) à Madagascar soutient la sécurité maritime en reliant le Centre régional de fusion d'informations maritimes (CRFIM) avec des partenaires mondiaux. L'OIT améliore le partage d'informations et la coordination, en aidant à faire face aux menaces maritimes dans la région de l'Afrique orientale et australe et de l'océan Indien (ESA-IO), en favorisant la collaboration régionale et internationale.",
      imageUrl: "/rcoc/ilomadagascar.png",
      flag: "/rcoc/madagascar.png",
      link: "#",
    },
    {
      title: "Seychelles",
      description:
        "L'officier de liaison internationale (OIT) à SeyChelles soutient la sécurité maritime en reliant le Centre régional de fusion d'informations maritimes (CRFIM) avec des partenaires mondiaux. L'OIT améliore le partage d'informations et la coordination, en aidant à faire face aux menaces maritimes dans la région de l'Afrique orientale et australe et de l'océan Indien (ESA-IO), en favorisant la collaboration régionale et internationale.",
      imageUrl: "/rcoc/iloseychelles.png",
      flag: "/rcoc/seychelles.png",
      link: "#",
    },
    {
      title: "Kenya",
      description:
        "L'officier de liaison internationale (OIT) au Kenya soutient la sécurité maritime en reliant le Centre régional de fusion d'informations maritimes (CRFIM) avec des partenaires mondiaux. L'OIT améliore le partage d'informations et la coordination, aidant à faire face aux menaces maritimes en Afrique orientale et australe et dans l'océan Indien ( ESA-IO), favorisant la collaboration régionale et internationale.",
      imageUrl: "/rcoc/iloKenya.png",
      flag: "/rcoc/madagascar.png",
      link: "#",
    },
  ];

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Carousel responsive={responsive}>
      {items.map((item, index) => (
        <div
          key={index}
          style={{
            padding: "1rem",
            border: "1px solid #ddd",
            borderRadius: "8px",
            backgroundColor: "#f9f9f9",
            textAlign: "center",
            margin: "1rem",
          }}
        >
          <Link to={item.link}>
            <img
              src={item.imageUrl}
              fill
              alt={item.title}
              className={Styles.image}
            />
            <div className={Styles.subContainer}>
              <h3>{item.title}</h3>
              <img
                src={item.flag}
                fill
                alt={item.title}
                className={Styles.imageFlag}
              />
            </div>
            <p>{item.description.substring(0, 120)}</p>
          </Link>
        </div>
      ))}
    </Carousel>
  );
};

export default SponsorsCarouselFR;
